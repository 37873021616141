@import "@/scss/_variables.scss";


































.image-cover {
  background-size: cover;
}
